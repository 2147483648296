import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SystemsService } from '@twaice-fe/frontend/shared/services';
import { selectors } from '@twaice-fe/frontend/shared/store';
import { MOBILITY_BASE_ROUTE, MOBILITY_DEPRECATED_BASE_ROUTE } from '@twaice-fe/shared/constants';
import { EnergyAnalyticsMetaData, FeatureFlagsEnum, KpisInterface, OverviewSystem, System } from '@twaice-fe/shared/models';
import { TimeRangeEnum, isEqual } from '@twaice-fe/shared/utilities';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { StorageMetadataDialogComponent } from './storage-metadata-dialog/storage-metadata-dialog.component';

const { systemSelectors, authSelectors, configsSelectors } = selectors;

@Component({
  selector: 'twaice-fe-navigation-menu',
  templateUrl: './navigation-menu.component.html',
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
  @Input() isFleet = false;
  @Input() isGlobal = false;
  @Input() isPerformanceManagerSolution = false;
  @Input() isSafetySolution = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  TOTAL_STORAGE_COUNT = 10;

  noSystemsAvailable = false;

  storages$: Observable<EnergyAnalyticsMetaData[]>;
  filteredStorages$: Observable<EnergyAnalyticsMetaData[]>;
  selectedSystem: OverviewSystem;

  selectedStorage$ = new BehaviorSubject<EnergyAnalyticsMetaData>(undefined);
  selectedStorageKpis$ = new BehaviorSubject<KpisInterface>(undefined);

  inputValue?: string;
  isPerformanceManagerSolutionEnabled = false;
  timeRangeEnum = TimeRangeEnum;

  private searchTerms = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(
    private systemsService: SystemsService,
    private store: Store,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.initComponentState();
    this.setupSearchSubscription();
  }

  ngOnInit(): void {
    this.setSelectedSystemFromRoute();
  }

  async onRowSelected(item: System) {
    await this.systemsService.setCurrentSystem(item);
    this.selectedSystem = item;
    this.setSelectedStorage();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openMetadataDialog() {
    this.dialog.open(StorageMetadataDialogComponent, {
      data: this.selectedStorage$.getValue().metadata,
    });
  }

  handleSearchInput(event) {
    const value = (event.target as HTMLInputElement).value;
    this.searchTerms.next(value);
  }

  private setupSearchSubscription() {
    this.searchTerms
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => {
          if (term === '') {
            return this.storages$;
          }

          return this.storages$.pipe(
            map((storages) => storages.filter((storage) => storage.name.toLowerCase().includes(term.toLowerCase())))
          );
        }),
        tap((filteredStorages) => {
          this.filteredStorages$ = of(filteredStorages);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private initComponentState() {
    this.checkPerformanceManagerSolution();
    this.isFleet = this.router.url.includes(MOBILITY_BASE_ROUTE) || this.router.url.includes(MOBILITY_DEPRECATED_BASE_ROUTE);
  }

  private checkPerformanceManagerSolution() {
    this.store
      .select(configsSelectors.getConfigFeatureFlagList)
      .pipe(distinctUntilChanged(isEqual))
      .subscribe((featureFlags) => {
        const listFeatureFlags = featureFlags as FeatureFlagsEnum[];
        this.isPerformanceManagerSolutionEnabled = listFeatureFlags.includes(
          FeatureFlagsEnum.ENERGY_PERFORMANCE_MANAGER_SHOW_BALANCING
        );
      });
  }

  private setSelectedSystemFromRoute() {
    this.store
      .select(systemSelectors.selectSystemByRoute)
      .pipe(
        tap((selected) => this.handleMissingSystemInRoute(selected)),
        filter((selected) => !!selected?.id),
        distinctUntilChanged((prev, curr) => prev.id === curr.id),
        switchMap((selectedSystem) => (selectedSystem ? of(selectedSystem) : this.systemsService.getCurrentSystem())),
        filter((selectedSystem) => !!selectedSystem),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedSystem) => {
        this.selectedSystem = selectedSystem;
        this.setStorages();
      });
  }

  private handleMissingSystemInRoute(selected: System) {
    if (selected) return;

    this.store
      .select(systemSelectors.getSystemList)
      .pipe(
        withLatestFrom(this.store.select(authSelectors.getUser)),
        filter(([, user]) => !!user),
        map(([systems]) => systems[0]),
        takeUntil(this.destroy$),
        tap((currentSystem) => {
          this.systemsService.setCurrentSystem(currentSystem);
          this.noSystemsAvailable = currentSystem?.id === '';
        })
      )
      .subscribe();
  }

  private setStorages() {
    this.storages$ = this.store
      .select(systemSelectors.getSystemList)
      .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)));

    this.storages$
      .pipe(
        take(1),
        tap(() => {
          this.setSelectedStorage();
        })
      )
      .subscribe();

    this.filteredStorages$ = this.storages$;
  }

  private setSelectedStorage() {
    if (!this.selectedSystem) return;

    this.store
      .select(systemSelectors.getSystemById(this.selectedSystem.id))
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedStorage) => {
        this.selectedStorage$.next(selectedStorage);
        if (selectedStorage.kpis) {
          this.selectedStorageKpis$.next(selectedStorage.kpis);
        }
      });
  }
}
