/* eslint-disable @typescript-eslint/naming-convention -- API defined structures */

export enum IncidentSortByTableEnum {
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  DURATION = 'duration',
  SEVERITY = 'severity',
}

export enum IncidentSortDirectionTableEnum {
  DESCENDING = 'descending',
  ASCENDING = 'ascending',
}

export enum IncidentSeverityEnum {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export enum IncidentRuleTypeEnum {
  THRESHOLD = 'threshold',
  ANOMALY = 'anomaly',
  TREND = 'trend',
}

export enum IncidentThresholdLevelEnum {
  CELL = 'cell',
  MODULE = 'module',
  STRING = 'string',
  INVERTER = 'inverter',
  CLUSTER = 'cluster',
  SYSTEM = 'system',
}

export enum SensorTypeEnum {
  CURRENT = 'current',
  VOLTAGE = 'voltage',
  TEMPERATURE = 'temperature',
  SPREADS = 'spreads',
}

export enum IncidentAggregationTypeEnum {
  MIN = 'min',
  MAX = 'max',
  AVG = 'avg',
  SUM = 'sum',
  SPREAD = 'spread',
}

export enum IncidentComputationType {
  MEASUREMENT = 'measurement',
  AGGREGATION = 'aggregation',
  CALCULATED = 'calculated_aggregation',
}

export enum IncidentValueType {
  VALUE = 'value',
  POSITION = 'position',
}

export interface IncidentRuleInterface {
  customerBk?: string;
  systemBk: string;
  systemName: string;
  ruleTypeBk: IncidentRuleTypeEnum;
  ruleBk: string;
}

export interface IncidentInterface extends IncidentRuleInterface {
  key: string;
  startTime: string; // The start time of the incident
  sensorBk: string; // sensor id
  sensorName: string;
  endTime: string; // The end time of the incident. Can be nullable in case of ongoing incidents
  lastUpdateTime: string; // The last timestamp of the measurement which was taken into account during monitoring
  duration: number; // The duration of the incident in seconds
  severity: IncidentSeverityEnum; // Severity level
  maxSeverity: IncidentSeverityEnum; // The maximum severity of an incident
  componentBk: string; // container id for which incident was detected
  componentName: string; // name of the component
  message: string; // The message which is sent with each incident
  dataExplorerUrl: string; // The URL with a link to the TWAICE data explorer  --> TBD if needs to be defined in the FE
  sensorData?: SingleIncidentSensorData[];
  systemID?: string;
}

export interface IncidentsCountInterface {
  low: number;
  medium: number;
  high: number;
  critical: number;
  total?: number;
}

export interface IncidentsAnalyticsInterface {
  customerBk: string;
  systemBk: string;
  systemTid: string;
  incidents: IncidentsCountInterface;
  totalIncidents: IncidentsCountInterface;
  ongoingIncidents: IncidentsCountInterface;
}

export interface SingleIncidentSensorData {
  value: number;
  unit: string;
  timestamp: string;
}

export interface SingleIncidentParams {
  customerBk: string;
  systemBk: string;
  ruleBk: string;
  sensorBk: string;
  startTime: string;
  ruleTypeBk: string;
  includeData?: boolean;
}

export interface SingleIncidentInterface extends IncidentInterface {
  sensorData?: SingleIncidentSensorData[];
  details: {
    [key in IncidentUpperThresholdEnum]: number;
  } &
    {
      [key in IncidentLowerThresholdEnum]: number;
    } & {
      maxSensorValue?: number;
      minSensorValue?: number;
    };
}

export enum IncidentUpperThresholdEnum {
  CRITICAL_UPPER_THRESHOLD = 'criticalUpperThreshold',
  HIGH_UPPER_THRESHOLD = 'highUpperThreshold',
  MEDIUM_UPPER_THRESHOLD = 'mediumUpperThreshold',
  LOW_UPPER_THRESHOLD = 'lowUpperThreshold',
}

export enum IncidentLowerThresholdEnum {
  CRITICAL_LOWER_THRESHOLD = 'criticalLowerThreshold',
  HIGH_LOWER_THRESHOLD = 'highLowerThreshold',
  MEDIUM_LOWER_THRESHOLD = 'mediumLowerThreshold',
  LOW_LOWER_THRESHOLD = 'lowLowerThreshold',
}

export interface SingeIncidentResponseInterface {
  status: string;
  data: SingleIncidentInterface;
}

export interface IncidentMetricsInterface {
  title: { label: string; translateKey: string; icon?: string };
  items: Array<{ label: string; translateKey: string; value: string }>;
  seeMore?: { label: string; url: string };
}

export interface IncidentRulesThresholdInterface {
  lowUpperThreshold: number;
  lowLowerThreshold: number;
  mediumUpperThreshold: number;
  mediumLowerThreshold: number;
  highUpperThreshold: number;
  highLowerThreshold: number;
  criticalUpperThreshold: number;
  criticalLowerThreshold: number;
  name: string;
  isActive: boolean;
  windowLength: number;
  level: IncidentThresholdLevelEnum;
  sensorType: SensorTypeEnum;
  valueType: IncidentValueType;
  computationType: IncidentComputationType;
  aggregationType: 'none' | IncidentAggregationTypeEnum;
  aggregationLevel: 'none' | IncidentThresholdLevelEnum;
  numberOfBreaches: number;
  componentBks: string[];
  customerBk: string;
  systemBk: string;
  ruleTypeBk: IncidentRuleTypeEnum;
  ruleBk: string;
  sensorName?: string;
  createdAt: Date;
}

export type IncidentThresholdType = IncidentRulesThresholdInterface;

export type IncidentRulesDefinitionType = Pick<
  IncidentRulesThresholdInterface,
  'name' | 'windowLength' | 'numberOfBreaches' | 'level' | 'customerBk' | 'systemBk' | 'ruleBk' | 'ruleTypeBk' | 'sensorType'
>;

export type IncidentRuleCreation = Omit<IncidentRulesThresholdInterface, 'ruleTypeBk' | 'sensorName' | 'createdAt'>;

export interface IncidentDelaysInterface {
  id: number;
  name: string;
  duration: string;
}

interface IncidentDelaysSystemConfigInterface {
  [key: string]: IncidentDelaysInterface[];
}

export interface IncidentSafetyScoreParams {
  customerBk: string;
  systemBk: string;
}

export interface IncidentSafetyScoreInterface {
  customerBk: string;
  systemBk: string;
  score: number;
}

export interface IncidentStorageOverviewParams {
  timeRangeStart: string;
  timeRangeEnd: string;
  customerBk: string;
  systemBk: string;
  parentComponentBk?: string;
  level?: string;
}
export interface IncidentComponentOverviewParams extends IncidentStorageOverviewParams {
  groupBy?: 'component' | 'sensor_name';
}

export type SafetyOverviewSeverity = 'critical' | 'medium' | 'low' | 'stable';

export interface IncidentStorageOverview {
  score: number;
  stringDistribution: {
    [key in SafetyOverviewSeverity]: Array<{ componentBk: string; name: string }>;
  };
}

export interface MailConfiguration {
  is_enabled: boolean;
}
export interface IncidentComponentOverview {
  customerBk: string;
  systemBk: string;
  groupBy: 'component' | 'sensor_type';
  aggregates: AggregateComponentOverview[];
}

export interface AggregateComponentOverview {
  name: string;
  criticalCount: number;
  mediumCount: number;
  lowCount: number;
  firstOccurrence: Date;
  lastOccurrence: Date;
  lowerBreaches: number[];
  upperBreaches: number[];
  displayBreaches?: string[];
  componentBk: string;
  affectedComponents?: Array<{
    componentBk: string;
    name: string;
  }>;
  affectedChildComponents: Array<{
    componentBk: string;
    name: string;
  }>;
  childComponentCount: number;
  sensorNames: string[];
  child: IncidentComponentOverview | undefined;
}

export const SYSTEM_INCIDENT_DELAYS_CONFIGS: IncidentDelaysSystemConfigInterface = {
  mustang: [
    { id: 1, name: 'String Current', duration: '90 min' },
    { id: 2, name: 'Inverter Current (Inv 1, Inv 3)', duration: '90 min' },
    { id: 3, name: 'Inverter Current (Inv 2, Inv 4)', duration: '90 min' },
    { id: 4, name: 'String Voltage', duration: '90 min' },
    { id: 5, name: 'Inverter Voltage', duration: '90 min' },
    { id: 6, name: 'String Cell Voltage Max', duration: '90 min' },
    { id: 7, name: 'String Cell Voltage Min', duration: '90 min' },
    { id: 8, name: 'String Module Temperature Max', duration: '90 min' },
    { id: 9, name: 'String Module Temperature Min', duration: '90 min' },
    { id: 10, name: 'String Module Temperature Spread', duration: '36 hours' },
    { id: 11, name: 'String Cell Voltage Spread', duration: '36 hours' },
  ],
  lazarus: [
    { id: 1, name: 'String Current', duration: '90 min' },
    { id: 2, name: 'Inverter Current (Inv 1, Inv 3)', duration: '90 min' },
    { id: 3, name: 'Inverter Current (Inv 2, Inv 4)', duration: '90 min' },
    { id: 4, name: 'String Voltage', duration: '90 min' },
    { id: 5, name: 'Inverter Voltage', duration: '90 min' },
    { id: 6, name: 'String Cell Voltage Max', duration: '90 min' },
    { id: 7, name: 'String Cell Voltage Min', duration: '90 min' },
    { id: 8, name: 'String Module Temperature Max', duration: '90 min' },
    { id: 9, name: 'String Module Temperature Min', duration: '90 min' },
    { id: 10, name: 'String Module Temperature Spread', duration: '36 hours' },
    { id: 11, name: 'String Cell Voltage Spread', duration: '36 hours' },
  ],
  percival: [
    { id: 1, name: 'String Current', duration: '90 min' },
    { id: 2, name: 'Inverter Current', duration: '90 min' },
    { id: 3, name: 'String Voltage', duration: '90 min' },
    { id: 4, name: 'Inverter Voltage', duration: '90 min' },
    { id: 5, name: 'String Cell Voltage Max', duration: '90 min' },
    { id: 6, name: 'String Cell Voltage Min', duration: '90 min' },
    { id: 7, name: 'String Module Temperature Max', duration: '90 min' },
    { id: 8, name: 'String Module Temperature Min', duration: '90 min' },
    { id: 9, name: 'String Module Temperature Spread', duration: '36 hours' },
    { id: 10, name: 'String Cell Voltage Spread', duration: '36 hours' },
  ],
  rtb: [
    { id: 1, name: 'String Current', duration: '90 min' },
    { id: 2, name: 'Inverter Current', duration: '90 min' },
    { id: 3, name: 'String Voltage', duration: '90 min' },
    { id: 4, name: 'Inverter Voltage', duration: '90 min' },
    { id: 5, name: 'String Cell Voltage Max', duration: '90 min' },
    { id: 6, name: 'String Cell Voltage Min', duration: '90 min' },
    { id: 7, name: 'String Module Temperature Max', duration: '90 min' },
    { id: 8, name: 'String Module Temperature Min', duration: '90 min' },
    { id: 9, name: 'String Module Temperature Spread', duration: '36 hours' },
    { id: 10, name: 'String Cell Voltage Spread', duration: '36 hours' },
  ],
  smaregII: [
    { id: 1, name: 'String Current', duration: '90 min' },
    { id: 2, name: 'Inverter Current', duration: '90 min' },
    { id: 3, name: 'String Voltage', duration: '90 min' },
    { id: 4, name: 'Inverter Voltage', duration: '90 min' },
    { id: 5, name: 'String Cell Voltage Max', duration: '90 min' },
    { id: 6, name: 'String Cell Voltage Min', duration: '90 min' },
    { id: 7, name: 'String Module Temperature Max', duration: '90 min' },
    { id: 8, name: 'String Module Temperature Min', duration: '90 min' },
    { id: 9, name: 'String Module Temperature Spread', duration: '36 hours' },
    { id: 10, name: 'String Cell Voltage Spread', duration: '36 hours' },
  ],
  euref: [
    { id: 1, name: 'String Current', duration: '90 min' },
    { id: 2, name: 'Inverter Current (Inv 1-3)', duration: '90 min' },
    { id: 3, name: 'Inverter Current (Inv 4)', duration: '90 min' },
    { id: 4, name: 'String Voltage', duration: '90 min' },
    { id: 5, name: 'Inverter Voltage', duration: '90 min' },
    { id: 6, name: 'String Cell Voltage Max', duration: '90 min' },
    { id: 7, name: 'String Cell Voltage Min', duration: '90 min' },
    { id: 8, name: 'String Module Temperature Max', duration: '90 min' },
    { id: 9, name: 'String Module Temperature Min', duration: '90 min' },
    { id: 10, name: 'String Module Temperature Spread', duration: '36 hours' },
    { id: 11, name: 'String Cell Voltage Spread', duration: '36 hours' },
  ],
  freiman: [
    { id: 1, name: 'String Current', duration: '90 min' },
    { id: 2, name: 'String Voltage', duration: '90 min' },
    { id: 3, name: 'String Module Temperature Max', duration: '90 min' },
    { id: 4, name: 'String Module Temperature Min', duration: '90 min' },
    { id: 5, name: 'String Module Temperature Spread', duration: '36 hours' },
  ],
};

export const SYSTEM_IDS_MAP: { [key: string]: string } = {
  'f52811ac-5e21-4f98-95c7-bf2e6d67509f': 'mustang',
  '737704f0-9e3f-418f-8bf6-e9abd1515a26': 'lazarus',
  '89b244e1-e41f-489d-9c17-11eadb5855cf': 'percival',
  'd23cee8c-c744-4879-9664-1ed8306fd1dc': 'euref',
  '0e0eefa5-acdd-4c61-8cc8-7a4d61c24ade': 'freiman',
  '334bc28a-c8ff-4981-91f0-1ae4cf121243': 'rtb',
  'c61d5bad-fcc6-4e7a-adda-2f6b1c720393': 'smaregII',
};
