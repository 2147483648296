<div class="w-full h-[48px] flex items-center sticky top-0 z-10 bg-white border-b-1 border-b-primary-border border-solid">
  <div class="flex items-center justify-between w-full">
    <ng-container *ngIf="!isGlobal; else globalTemplate">
      <div data-type="storage-info" class="flex items-center">
        <button
          nz-button
          nz-dropdown
          nzTrigger="click"
          nzPlacement="bottomLeft"
          [nzDropdownMenu]="menu"
          class="flex items-center gap-8 rounded-sm p-16 text-sm leading-lg text-primary-text"
          [attr.data-intercom-target]="intercomTarget + '-storage-dropdown-button'"
        >
          <ng-container *ngIf="(selectedStorage$ | async)?.name; else loadingTemplate">
            <twaice-fe-svg-icon name="icon_server" />
            {{ (selectedStorage$ | async)?.name }}
          </ng-container>
          <ng-template #loadingTemplate>
            @if (noSystemsAvailable) { No storage } @else {
            <div class="flex items-center justify-center gap-8 p-24">
              <nz-spin nzSimple [nzSize]="'small'" class="mt-8"></nz-spin>
              <span class="text-sm">Loading ...</span>
            </div>
            }
          </ng-template>
          <twaice-fe-svg-icon name="keyboard_arrow_down" source="material" />
        </button>

        <twaice-fe-time-selector
          *ngIf="(isPerformanceManagerSolution && isPerformanceManagerSolutionEnabled) || isSafetySolution"
          [defaultTimeRange]="isPerformanceManagerSolution ? timeRangeEnum.LAST_SIXTY_DAYS : timeRangeEnum.LAST_THIRTY_DAYS"
        ></twaice-fe-time-selector>
      </div>
    </ng-container>

    <div
      *ngIf="!isFleet && !isGlobal"
      data-type="metadata"
      class="flex items-center gap-24 text-sm px-13 mr-8"
      [attr.data-intercom-target]="intercomTarget + '-energy-storage-info'"
    >
      <twaice-fe-svg-icon
        name="icon_tree"
        class="cursor-pointer"
        size="20px"
        (click)="openMetadataDialog()"
        [attr.data-intercom-target]="intercomTarget + '-storage-metadata-button'"
      />

      <ng-container *ngIf="selectedStorageKpis$ && selectedStorageKpis$ | async as selectedStorageKpis">
        <div class="flex flex-col md:flex-row md:items-center md:gap-8 leading-base text-primary-text">
          <span class="text-secondary-text">Cycles last 30 days</span>
          <twaice-fe-data-display numberFormat="1.0-0" [value]="selectedStorageKpis?.efcSum ?? '--'" />
        </div>

        <div class="flex flex-col md:flex-row md:items-center md:gap-8 leading-base text-primary-text">
          <span class="text-secondary-text">Total cycles</span>
          <twaice-fe-data-display numberFormat="1.0-0" [value]="selectedStorageKpis?.efcAvg ?? '--'" />
        </div>

        <div class="flex flex-col md:flex-row md:items-center md:gap-8 leading-base">
          <span class="text-secondary-text">Min. SoH</span>
          <twaice-fe-data-display numberFormat="1.0-0" measurand="%" [value]="selectedStorageKpis?.sohCMin ?? '--'" />
        </div>

        <div class="flex flex-col md:flex-row md:items-center md:gap-8 leading-base">
          <span class="text-secondary-text">Max. SoH</span>
          <twaice-fe-data-display numberFormat="1.0-0" measurand="%" [value]="selectedStorageKpis?.sohCMax ?? '--'" />
        </div>
      </ng-container>
    </div>
  </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul class="p-4 max-h-[400px] overflow-y-auto rounded-lg" nz-menu>
    <ng-container *ngIf="(storages$ | async)?.length > 0">
      <div class="px-24 py-16 rounded-lg" *ngIf="(storages$ | async)?.length > TOTAL_STORAGE_COUNT">
        <input class="p-8" nz-input placeholder="Search ..." [(ngModel)]="inputValue" (input)="handleSearchInput($event)" />
      </div>
      <li
        nz-menu-item
        *ngFor="let storage of filteredStorages$ | async"
        class="hover:bg-gray-100 rounded-lg"
        (click)="onRowSelected(storage)"
      >
        <ng-container *ngIf="!isFleet; else fleetTemplate">
          <div class="px-24 py-16 grid grid-cols-[1fr_auto_auto_auto] gap-16">
            <span class="text-sm font-semibold leading-lg w-[110px]">{{ storage.name }}</span>
            <span class="text-tertiary-text text-xs leading-lg w-[150px]">{{ storage?.metadata?.location }}</span>
            <span class="text-tertiary-text text-xs leading-lg w-64">{{ storage?.metadata?.nominalEnergyCapacity }}</span>
            <span class="text-tertiary-text text-xs leading-lg w-64">{{ storage?.metadata?.maximumPower }}</span>
          </div>
        </ng-container>

        <ng-template #fleetTemplate>
          <div class="px-24 py-16 flex items-center gap-16 bg-white">
            <span class="text-sm font-semibold leading-lg">{{ storage.name }}</span>
          </div>
        </ng-template>
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>

<ng-template #globalTemplate>
  <div class="flex items-center gap-8 p-16 pl-[14px] text-sm leading-lg text-primary-text">
    <twaice-fe-svg-icon name="icon_server" /> All Storages
  </div>
</ng-template>
