import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectors } from '@twaice-fe/frontend/shared/store';
import * as routes from '@twaice-fe/shared/constants';
import { FeatureFlagsEnum, Solution } from '@twaice-fe/shared/models';
import { distinctUntilChanged, map, tap, withLatestFrom } from 'rxjs';
import { isEqual } from '@twaice-fe/shared/utilities';

const { configsSelectors, systemSelectors } = selectors;

/**
 * Guard for protecting routes that the user does not have access to
 */
export const hasSolution =
  (solution: Solution): CanActivateFn =>
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const [store, router] = [inject(Store), inject(Router)];

    return store.select(configsSelectors.getAvailableSolutionList).pipe(
      takeUntilDestroyed(),
      map((solutions) => solutions.some((s) => s === solution)),
      withLatestFrom(store.select(systemSelectors.getSelectedId)),
      // check if we have an empty page for the solution so we can navigate there instead of 404ing
      map(([canNavigate, selectedSystemId]) => {
        // avoid user "cheating" out of empty page into solution sub pages
        if (state.url.includes('/empty') && router.url.replace('/empty', '') === state.url.replace('/empty', '')) return false;

        // if user has solution -> just continue
        if (canNavigate || state.url.includes('/empty')) return true;

        if (state.url.includes(routes.ENERGY_INCIDENTS_ROUTE)) {
          return router.parseUrl(`${routes.ENERGY_INCIDENTS_ROUTE}/empty?systemID=${selectedSystemId}`);
        }

        if (state.url.includes(routes.ENERGY_WARRANTY_ROUTE)) {
          return router.parseUrl(`${routes.ENERGY_WARRANTY_ROUTE}/empty?systemID=${selectedSystemId}`);
        }

        if (state.url.includes(routes.ENERGY_PREDICTION_ROUTE)) {
          return router.parseUrl(`${routes.ENERGY_PREDICTION_ROUTE}/empty?systemID=${selectedSystemId}`);
        }

        if (state.url.includes(routes.MODEL_LIBRARY_BASE_ROUTE)) {
          return router.parseUrl(`${routes.MODEL_LIBRARY_BASE_ROUTE}/empty?systemID=${selectedSystemId}`);
        }

        if (state.url.includes(routes.ENERGY_PERFORMANCE_MANAGER_ROUTE)) {
          return router.parseUrl(`${routes.ENERGY_PERFORMANCE_MANAGER_ROUTE}/empty?systemID=${selectedSystemId}`);
        }

        return false;
      }),
      tap((canNavigate) => canNavigate || router.parseUrl('/404'))
    );
  };

/**
 * Guard for navigating the user to their respective landing page, given their customer type
 */
export const redirectToSolutionLanding: CanActivateFn = () => {
  const [router, store] = [inject(Router), inject(Store)];

  return store.select(configsSelectors.getBaseUrl).pipe(
    takeUntilDestroyed(),
    map((baseUrl) => (baseUrl ? router.parseUrl(baseUrl) : router.parseUrl('/missing-configuration')))
  );
};

export const hasFeatureFlag =
  ({ featureFlag, redirectPath }: { featureFlag: FeatureFlagsEnum; redirectPath?: string }): CanActivateFn =>
  () => {
    const [router, store] = [inject(Router), inject(Store)];

    return store.select(configsSelectors.getConfigFeatureFlagList).pipe(
      distinctUntilChanged(isEqual),
      map((featureFlags) => {
        const listFeatureFlags = featureFlags as FeatureFlagsEnum[];
        const isAvailable = listFeatureFlags.includes(featureFlag);
        if (!isAvailable) {
          if (!redirectPath) return false;
          return router.parseUrl(redirectPath || '/404');
        }
        return true;
      })
    );
  };
