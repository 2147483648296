import { createReducer, on } from '@ngrx/store';
import {
  EnergyLossDataInterface,
  PerformanceManagerKPI,
  StringKPIInterface,
  SystemInsightsInterface,
  SystemKPIInterface,
  SystemSeverityLevelResultInterface,
  SystemStatisticsData,
} from '@twaice-fe/shared/models';
import * as performanceManagerActions from '../actions/performance-manager.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const PERFORMANCE_MANAGER_FEATURE_KEY = 'performance-manager';

export interface SystemStatisticsState {
  kpi: PerformanceManagerKPI;
  levelBk: string;
  isLoading: boolean;
  error?: string | null;
  data: EntityState<SystemStatisticsData>;
  selectedId?: number;
}

export interface SystemKPIState extends SystemKPIInterface {
  kpi: PerformanceManagerKPI;
  levelBk: string;
  isLoading: boolean;
  error?: string | null;
}

export interface SystemInsightsState extends SystemInsightsInterface {
  kpi: PerformanceManagerKPI;
  levelBk: string;
  isLoading: boolean;
  error?: string | null;
}

export interface SystemSeverityLevelState extends SystemSeverityLevelResultInterface {
  isLoading: boolean;
  error?: string | null;
}

export interface StringKPIState extends StringKPIInterface {
  kpi: PerformanceManagerKPI;
  levelBk: string;
  inverterBk: string;
  isLoading: boolean;
  error?: string | null;
}

export interface EnergyLossState {
  isLoading: boolean;
  error?: string | null;
  data: EntityState<EnergyLossDataInterface>;
}

export interface State {
  customerBk: string;
  systemBk: string;
  balancingSystemStatistics: SystemStatisticsState;
  temperatureSystemStatistics: SystemStatisticsState;
  systemKPIData: {
    inverter: SystemKPIState;
    string: SystemKPIState;
  };
  systemInsights: {
    balancing: SystemInsightsState;
    temperature: {
      [PerformanceManagerKPI.TEMPERATURE_MAX]: SystemInsightsState;
      [PerformanceManagerKPI.TEMPERATURE_SPREAD]: SystemInsightsState;
      [PerformanceManagerKPI.TEMPERATURE_MEAN]: SystemInsightsState;
    };
  };
  systemSeverityLevel: SystemSeverityLevelState;
  stringKPIData: StringKPIState;
  energyLoss: EnergyLossState
}

export const balancingSystemStatisticsAdapter: EntityAdapter<SystemStatisticsData> = createEntityAdapter<SystemStatisticsData>({
  selectId: (systemStatistics) => systemStatistics.x,
});

export const temperatureSystemStatisticsAdapter: EntityAdapter<SystemStatisticsData> = createEntityAdapter<SystemStatisticsData>({
  selectId: (systemStatistics) => systemStatistics.x,
});


export const energyLossAdapter: EntityAdapter<EnergyLossDataInterface> = createEntityAdapter<EnergyLossDataInterface>({
  selectId: (energyLossData) => energyLossData.systemBk,
});

export const initialEnergyLossState: EnergyLossState = {
  data: energyLossAdapter.getInitialState(),
  isLoading: false,
}

export const initialSystemStatisticsState: SystemStatisticsState = {
  kpi: PerformanceManagerKPI.SOC_SPREAD,
  levelBk: '',
  data: balancingSystemStatisticsAdapter.getInitialState(),
  selectedId: undefined,
  isLoading: false,
};

export const initialBalancingSystemStatisticsState: SystemStatisticsState = {
  kpi: PerformanceManagerKPI.SOC_SPREAD,
  levelBk: '',
  data: balancingSystemStatisticsAdapter.getInitialState(),
  selectedId: undefined,
  isLoading: false,
};

export const initialTemperatureSystemStatisticsState: SystemStatisticsState = {
  kpi: PerformanceManagerKPI.TEMPERATURE_MAX,
  levelBk: '',
  data: temperatureSystemStatisticsAdapter.getInitialState(),
  selectedId: undefined,
  isLoading: false,
};

export const initialSystemKPIState: SystemKPIState = {
  kpi: PerformanceManagerKPI.SOC_SPREAD,
  levelBk: '',
  data: [],
  isLoading: false,
};

const initialSystemInsightsState: SystemInsightsState = {
  kpi: PerformanceManagerKPI.SOC_SPREAD,
  levelBk: '',
  data: [],
  isLoading: false,
};

const initialSystemSeverityLevelState: SystemSeverityLevelState = {
  data: {},
  isLoading: false,
};

export const initialStringKPIState: StringKPIState = {
  kpi: PerformanceManagerKPI.SOC_SPREAD,
  levelBk: '',
  inverterBk: '',
  data: [],
  isLoading: false,
};

export const initialState: State = {
  customerBk: '',
  systemBk: '',
  balancingSystemStatistics: { ...initialBalancingSystemStatisticsState },
  temperatureSystemStatistics: { ...initialTemperatureSystemStatisticsState },
  systemKPIData: {
    inverter: { ...initialSystemKPIState },
    string: { ...initialSystemKPIState },
  },
  systemInsights: {
    balancing: { ...initialSystemInsightsState },
    temperature: {
      [PerformanceManagerKPI.TEMPERATURE_MAX]: { ...initialSystemInsightsState },
      [PerformanceManagerKPI.TEMPERATURE_SPREAD]: { ...initialSystemInsightsState },
      [PerformanceManagerKPI.TEMPERATURE_MEAN]: { ...initialSystemInsightsState },
    },
  },
  systemSeverityLevel: { ...initialSystemSeverityLevelState },
  stringKPIData: { ...initialStringKPIState },
  energyLoss: { ...initialEnergyLossState }
};

export const performanceManagerReducer = createReducer(
  initialState,
  on(performanceManagerActions.fetchBalancingSystemStatistics, (state) => ({
    ...state,
    balancingSystemStatistics: { ...initialBalancingSystemStatisticsState },
  })),
  on(performanceManagerActions.loadBalancingSystemStatisticsSuccess, (state, { balancingSystemStatistics }) => ({
    ...state,
    balancingSystemStatistics: {
      ...state.balancingSystemStatistics,
      ...balancingSystemStatistics,
      data: balancingSystemStatisticsAdapter.upsertMany(balancingSystemStatistics.data, state.balancingSystemStatistics.data),
      isLoading: false,
      error: null,
    },
  })),
  on(performanceManagerActions.loadBalancingSystemStatisticsFailure, (state, { error }) => ({
    ...state,
    balancingSystemStatistics: {
      ...state.balancingSystemStatistics,
      isLoading: false,
      error,
    },
  })),
  on(performanceManagerActions.selectBalancingSystemStatisticsDay, (state, { selectedDay }) => ({
    ...state,
    balancingSystemStatistics: {
      ...state.balancingSystemStatistics,
      selectedId: selectedDay,
    },
  })),
  on(performanceManagerActions.fetchTemperatureSystemStatistics, (state) => ({
    ...state,
    temperatureSystemStatistics: { ...initialTemperatureSystemStatisticsState },
  })),
  on(performanceManagerActions.loadTemperatureSystemStatisticsSuccess, (state, { temperatureSystemStatistics }) => ({
    ...state,
    temperatureSystemStatistics: {
      ...state.temperatureSystemStatistics,
      ...temperatureSystemStatistics,
      data: temperatureSystemStatisticsAdapter.upsertMany(
        temperatureSystemStatistics.data,
        state.temperatureSystemStatistics.data
      ),
      isLoading: false,
      error: null,
    },
  })),
  on(performanceManagerActions.loadTemperatureSystemStatisticsFailure, (state, { error }) => ({
    ...state,
    temperatureSystemStatistics: {
      ...state.temperatureSystemStatistics,
      isLoading: false,
      error,
    },
  })),
  on(performanceManagerActions.selectTemperatureSystemStatisticsDay, (state, { selectedDay }) => ({
    ...state,
    temperatureSystemStatistics: {
      ...state.temperatureSystemStatistics,
      selectedId: selectedDay,
    },
  })),
  on(performanceManagerActions.fetchSystemKPIData, (state) => ({
    ...state,
    systemKPIData: {
      inverter: { ...state.systemKPIData.inverter },
      string: { ...state.systemKPIData.string },
    },
  })),
  on(performanceManagerActions.loadSystemKPIDataSuccess, (state, { systemKPIData }) => ({
    ...state,
    systemKPIData: {
      ...state.systemKPIData,
      // @ts-ignore
      [systemKPIData.levelBk]: {
        // @ts-ignore
        ...state.systemKPIData[systemKPIData.levelBk],
        ...systemKPIData,
        data: systemKPIData.data,
        isLoading: false,
        error: null,
      },
    },
  })),
  on(performanceManagerActions.loadSystemKPIDataFailure, (state, { error }) => ({
    ...state,
    systemKPIData: {
      ...state.systemKPIData,
      inverter: { ...state.systemKPIData.inverter, isLoading: false, error },
      string: { ...state.systemKPIData.string, isLoading: false, error },
    },
  })),
  on(performanceManagerActions.fetchSystemInsights, (state) => ({
    ...state,
    systemInsights: {
      temperature: {
        [PerformanceManagerKPI.TEMPERATURE_MAX]: {
          ...state.systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MAX],
          data: [],
          isLoading: true,
        },
        [PerformanceManagerKPI.TEMPERATURE_SPREAD]: {
          ...state.systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_SPREAD],
          data: [],
          isLoading: true,
        },
        [PerformanceManagerKPI.TEMPERATURE_MEAN]: {
          ...state.systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MEAN],
          data: [],
          isLoading: true,
        },
      },
      balancing: { ...state.systemInsights.balancing, data: [], isLoading: true },
    },
  })),
  on(performanceManagerActions.loadSystemInsightsSuccess, (state, { systemInsights }) => ({
    ...state,
    systemInsights: {
      ...state.systemInsights,
      ...(systemInsights.balancing && {
        balancing: {
          ...state.systemInsights.balancing,
          ...systemInsights.balancing,
          data: systemInsights.balancing.data,
          isLoading: false,
          error: null,
        },
      }),
      ...(systemInsights.temperature &&
        systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MAX] && {
          temperature: {
            ...state.systemInsights.temperature,
            [PerformanceManagerKPI.TEMPERATURE_MAX]: {
              ...state.systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MAX],
              ...systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MAX],
              data: systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MAX]?.data,
              isLoading: false,
              error: null,
            },
          },
        }),
      ...(systemInsights.temperature &&
        systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_SPREAD] && {
          temperature: {
            ...state.systemInsights.temperature,
            [PerformanceManagerKPI.TEMPERATURE_SPREAD]: {
              ...state.systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_SPREAD],
              ...systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_SPREAD],
              data: systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_SPREAD]?.data,
              isLoading: false,
              error: null,
            },
          },
        }),
      ...(systemInsights.temperature &&
        systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MEAN] && {
          temperature: {
            ...state.systemInsights.temperature,
            [PerformanceManagerKPI.TEMPERATURE_MEAN]: {
              ...state.systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MEAN],
              ...systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MEAN],
              data: systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MEAN]?.data,
              isLoading: false,
              error: null,
            },
          },
        }),
    },
  })),
  on(performanceManagerActions.loadSystemInsightsFailure, (state, { error }) => ({
    ...state,
    systemInsights: {
      ...state.systemInsights,
      balancing: { ...state.systemInsights.balancing, isLoading: false, error },
      temperature: {
        ...state.systemInsights.temperature,
        [PerformanceManagerKPI.TEMPERATURE_MAX]: {
          ...state.systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MAX],
          isLoading: false,
          error,
        },
        [PerformanceManagerKPI.TEMPERATURE_SPREAD]: {
          ...state.systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_SPREAD],
          isLoading: false,
          error,
        },
        [PerformanceManagerKPI.TEMPERATURE_MEAN]: {
          ...state.systemInsights.temperature[PerformanceManagerKPI.TEMPERATURE_MEAN],
          isLoading: false,
          error,
        },
      },
    },
  })),
  on(performanceManagerActions.fetchSystemSeverityLevel, (state) => ({
    ...state,
    systemSeverityLevel: { ...initialSystemSeverityLevelState },
  })),
  on(performanceManagerActions.loadSystemSeverityLevelSuccess, (state, { systemSeverityLevel }) => ({
    ...state,
    systemSeverityLevel: {
      ...state.systemSeverityLevel,
      data: {
        ...state.systemSeverityLevel.data,
        ...systemSeverityLevel
      },
      isLoading: false,
      error: null,
    },
  })),
  on(performanceManagerActions.loadSystemSeverityLevelFailure, (state, { error }) => ({
    ...state,
    systemSeverityLevel: {
      ...state.systemSeverityLevel,
      isLoading: false,
      error,
    },
  })),
  on(performanceManagerActions.fetchStringKPIData, (state) => ({
    ...state,
    stringKPIData: { ...initialStringKPIState },
  })),
  on(performanceManagerActions.loadStringKPIDataSuccess, (state, { stringKPIData }) => ({
    ...state,
    stringKPIData: {
      ...state.stringKPIData,
      ...stringKPIData,
      isLoading: false,
      error: null,
    },
  })),
  on(performanceManagerActions.loadStringKPIDataFailure, (state, { error }) => ({
    ...state,
    stringKPIData: {
      ...state.stringKPIData,
      isLoading: false,
      error,
    },
  })),
  on(performanceManagerActions.fetchEnergyLossData, ( state ) => ({
    ...state,
    energyLoss: {
      ...state.energyLoss,
      isLoading: true
    }
  })),
  on(performanceManagerActions.loadEnergyLossSuccess, (state, { energyLossData }) => ({
    ...state,
    energyLoss: {
      ...state.energyLoss,
      data: energyLossAdapter.upsertMany(energyLossData, state.energyLoss.data),
      isLoading: false
    }
  })),
  on(performanceManagerActions.loadEnergyLossFailure, (state, { error }) => ({
    ...state,
    energyLoss: {
      ...state.energyLoss,
      isLoading: false,
      error
    }
  }))
);
