<div class="content-wrapper flex flex-col h-screen w-screen overflow-hidden bg-white">
  <twaice-fe-toolbar data-intercom-target="dashboard-layout-toolbar" />

  <div class="flex w-full h-full flex-grow-default min-h-0">
    <twaice-fe-dashboard-sidenav class="bg-white" data-intercom-target="dashboard-layout-sidenav" />

    <div class="router-content flex flex-1 flex-col w-full overflow-y-auto">
      <twaice-fe-navigation-menu
        *ngIf="showNavigationMenu"
        [isGlobal]="showGlobalNavigatioMenu"
        [isFleet]="isFleet"
        [isPerformanceManagerSolution]="isPerformanceManagerSolution"
        [isSafetySolution]="isSafetySolution"
        data-intercom-target="dashboard-layout-navigation-menu"
      />
      <div class="w-full h-full overflow-y-auto" [class.p-32]="!isFleet && !isPerformanceManagerSolution && !isAssetDashboard && !isSafetySolution">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
